import React, { useEffect, useState } from 'react';
import { Paper, Typography } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { requestWrapper } from 'lib/api/auth';
import { BASE_URL } from 'lib/api/client';
import { useTranslation } from 'react-i18next';

export interface MedianPageTimeChartProps {
  selectedDocumentInstanceId: string | undefined;
}

const MedianPageTimeChart: React.FC<MedianPageTimeChartProps> = ({ selectedDocumentInstanceId }) => {
  const [data, setData] = useState<any>([]);
  const [maxY, setMaxY] = useState<number>(0);
  const { t } = useTranslation("global");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await requestWrapper(`${BASE_URL}/api/v1/page_visited/average_durations_by_page_for_passcode?passcode_id=${selectedDocumentInstanceId}`);
  
        const averageKey = t("mainSection.average-graph-key");
        const pageLabel = t("mainSection.page");
  
        const dataArray = Object.entries(response)
          .sort((a, b) => parseInt(a[0], 10) - parseInt(b[0], 10))
          .map(([key, value], index) => {
            const numericValue = typeof value === 'number' ? value : parseFloat(value as string);
            return {
              page: `${pageLabel} ${index + 1}`,
              [averageKey]: numericValue.toFixed(3),
            };
          });
  
        const maxValue = Math.round(
          (Math.max(...dataArray.map(item => parseFloat(item[averageKey] || "0"))) * 1.1) / 10
        ) * 10;
  
        setData(dataArray);
        setMaxY(maxValue);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    if (selectedDocumentInstanceId) {
      fetchData();
    }
  }, [selectedDocumentInstanceId, t]);
  

  return (
    <Paper style={{ margin: 20, width: '100%' }}>
      <Typography variant="h4" style={{ margin: 10 }}>
      {t("mainSection.average-time-title")}
      </Typography>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="page" />
          <YAxis domain={[0, (dataMax: number) => (maxY)]} />
          <Tooltip />
          <Legend />
          <Bar dataKey={t("mainSection.average-graph-key")} fill="#2566e8" />
        </BarChart>
      </ResponsiveContainer>

    </Paper>
  );
};

export default MedianPageTimeChart;