import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
  Alert,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { requestWrapper } from "lib/api/auth";
import { BASE_URL } from "lib/api/client";
import { useTranslation } from "react-i18next";

interface ScriptResponse {
  script?: string;
  message?: string;
  error?: string;
}

const ExternalScriptForm: React.FC = () => {
  const [scriptContent, setScriptContent] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [message, setMessage] = useState<string | null>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { t } = useTranslation("global");

  useEffect(() => {
    const fetchScript = async () => {
      try {
        const response: ScriptResponse = await requestWrapper(
          `${BASE_URL}/api/v1/external_scripts`,
          { method: "GET" }
        );
        if (response.script) {
          setScriptContent(response.script);
        }
      } catch (error) {
        console.error("Failed to fetch script:", error);
        setMessage("No existing script found.");
      } finally {
        setLoading(false);
      }
    };

    fetchScript();
  }, []);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setMessage(null);

    try {
      const response: ScriptResponse = await requestWrapper(
        `${BASE_URL}/api/v1/external_scripts`,
        {
          method: "POST",
          body: JSON.stringify({ script_content: scriptContent }),
          headers: { "Content-Type": "application/json" },
        }
      );

      if (response.message) {
        setMessage("Script saved successfully!");
        setIsEditing(false);
      }
    } catch (error) {
      console.error("Error saving script:", error);
      setMessage("Failed to save script.");
    }
  };

  return (
    <Box
      sx={{
        maxWidth: 600,
        margin: "auto",
        padding: 3,
        border: "1px solid #ccc",
        borderRadius: 2,
        position: "relative",
      }}
    >
      <Typography variant="h5" sx={{ marginBottom: 2 }}>
        {t("mainSection.scripts-settings-title")}
      </Typography>

      {!isEditing && (
        <IconButton
          onClick={() => setIsEditing(true)}
          sx={{ position: "absolute", top: 10, right: 10 }}
          aria-label="Edit Script"
        >
          <EditIcon />
        </IconButton>
      )}

      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {message && (
            <Alert severity={message.toLowerCase().includes("failed") ? "error" : "success"}>
              {message}
            </Alert>
          )}

          <form onSubmit={handleSubmit}>
            <TextField
              label="External Script"
              multiline
              rows={8}
              fullWidth
              variant="outlined"
              value={scriptContent}
              onChange={(e) => setScriptContent(e.target.value)}
              InputProps={{
                readOnly: !isEditing,
                style: { fontFamily: "monospace" },
              }}
              sx={{ marginBottom: 2 }}
            />

            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={!isEditing}
            >
              Save Script
            </Button>
          </form>
        </>
      )}
    </Box>
  );
};

export default ExternalScriptForm;
