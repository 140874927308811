import React, { useState, useEffect, useRef, useCallback } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import * as pdfjsDist from "pdfjs-dist";
import { useSwipeable } from "react-swipeable";
import PDFControlPanel from "./PDFControlPanel"; // ✅ Import control panel
import { PDFJS_WORKER } from "components/utils/Constants";

pdfjsDist.GlobalWorkerOptions.workerSrc = PDFJS_WORKER;

type MobilePdfReaderProps = {
  fileUrl: string;
};

const BASE_RATIO = 0.8;
const MIN_ZOOM = 0.5;
const MAX_ZOOM = 2;

const MobilePdfReader: React.FC<MobilePdfReaderProps> = ({ fileUrl }) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [zoom, setZoom] = useState(BASE_RATIO);
  const pdfContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleResize = () => {
      document.documentElement.style.setProperty("--pdf-zoom", zoom.toString());
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [zoom]);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const increaseZoom = () => setZoom((prev) => Math.min(prev + 0.1, MAX_ZOOM));
  const decreaseZoom = () => setZoom((prev) => Math.max(prev - 0.1, MIN_ZOOM));

  // ✅ Next/Previous Page Functions
  const goToNextPage = () => setCurrentPage((prev) => Math.min(prev + 1, numPages || prev));
  const goToPrevPage = () => setCurrentPage((prev) => Math.max(prev - 1, 0));

  // ✅ Swipe Gestures for Page Navigation
  const handlers = useSwipeable({
    onSwipedLeft: goToNextPage,
    onSwipedRight: goToPrevPage,
    preventScrollOnSwipe: true,
    trackTouch: true,
  });

  // ✅ Combine refs properly
  const mergedRef = useCallback(
    (el: HTMLDivElement | null) => {
      pdfContainerRef.current = el;
      handlers.ref(el); // Attach swipe handlers
    },
    [handlers]
  );

  return (
    <>
      {/* ✅ Fixed Bottom Control Panel */}
      <PDFControlPanel
        zoom={zoom}
        onZoomIn={increaseZoom}
        onZoomOut={decreaseZoom}
        minZoom={MIN_ZOOM}
        maxZoom={MAX_ZOOM}
        currentPageIndex={currentPage-1}
        numPages={numPages}
        onNextPage={goToNextPage}
        onPrevPage={goToPrevPage}
      />

      {/* ✅ Full-Screen Centered PDF Viewer */}
      <div
        ref={mergedRef} // ✅ Fix: Merged ref (No Conflict)
        className="w-full h-screen bg-gray-900 text-white flex items-center justify-center"
        style={{ overflow: "hidden" }} // ✅ No scroll
      >
        <Document file={fileUrl} onLoadSuccess={onDocumentLoadSuccess}>
          <Page
            pageNumber={currentPage}
            width={window.innerWidth * zoom} // ✅ Keep PDF centered & zoomable
            renderTextLayer={false}
            renderAnnotationLayer={false}
          />
        </Document>
      </div>
    </>
  );
};

export default MobilePdfReader;
