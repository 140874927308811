export function polyfillPromiseWithResolvers() {
  if (typeof Promise.withResolvers === "undefined") {
    Object.defineProperty(Promise, "withResolvers", {
      value: function <T>() {
        let resolve!: (value: T | PromiseLike<T>) => void;
        let reject!: (reason?: any) => void;
        const promise = new Promise<T>((res, rej) => {
          resolve = res;
          reject = rej;
        });
        return { promise, resolve, reject };
      },
      writable: false,
      configurable: false,
      enumerable: false,
    });
  }
}
