import React, { useEffect, useRef, useCallback } from "react";
import { Document, Page } from "react-pdf";
import * as pdfjsDist from "pdfjs-dist";
import { useSwipeable } from "react-swipeable";
import { PDFJS_WORKER } from "components/utils/Constants";
import { useSearchParams } from "react-router-dom";

pdfjsDist.GlobalWorkerOptions.workerSrc = PDFJS_WORKER;

type SinglePagePdfViewerProps = {
  fileUrl: string;
  zoomProp: number;
  onNext: () => void; 
  onPrev: () => void;
};

const SinglePagePdfViewer: React.FC<SinglePagePdfViewerProps> = ({ fileUrl, onNext, onPrev, zoomProp }) => {
  const pdfContainerRef = useRef<HTMLDivElement | null>(null);
  const [searchParams] = useSearchParams();
  const swipDirection = searchParams.get('dir');

  useEffect(() => {
    const handleResize = () => {
      document.documentElement.style.setProperty("--pdf-zoom", zoomProp.toString());
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [zoomProp]); 

  const handlers = useSwipeable({
    onSwipedLeft: swipDirection === "1" ? onPrev : onNext,
    onSwipedRight: swipDirection === "1" ? onNext : onPrev,
    preventScrollOnSwipe: true,
    trackTouch: true,
  });

  const mergedRef = useCallback(
    (el: HTMLDivElement | null) => {
      pdfContainerRef.current = el;
      handlers.ref(el);
    },
    [handlers]
  );

  return (
    <div
      ref={mergedRef}
      className="w-full h-screen bg-gray-900 text-white flex items-center justify-center"
      style={{ overflow: "hidden" }}
    >
      <Document file={fileUrl}>
        <Page
          pageNumber={1}
          width={window.innerWidth * zoomProp}
          renderTextLayer={false}
          renderAnnotationLayer={false}
        />
      </Document>
    </div>
  );
};

export default SinglePagePdfViewer;
