import React from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

type PDFControlPanelProps = {
  zoom: number;
  onZoomIn: () => void;
  onZoomOut: () => void;
  minZoom: number;
  maxZoom: number;
  currentPageIndex: number;
  numPages: number | null;
  onNextPage: () => void;
  onPrevPage: () => void;
};

const PDFControlPanel: React.FC<PDFControlPanelProps> = ({
  zoom,
  onZoomIn,
  onZoomOut,
  minZoom,
  maxZoom,
  currentPageIndex,
  numPages,
  onNextPage,
  onPrevPage,
}) => {
  const { t } = useTranslation("global");
  const [searchParams] = useSearchParams();
  const swipDirection = searchParams.get('dir');

  return (
    <div
      style={{
        position: "fixed",
        // top: "0",
        bottom: "30px",
        left: "0",
        width: "100%",
        background: "rgba(0, 0, 0, 0.8)",
        color: "white",
        padding: "10px 10px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "column",
        zIndex: 1000,
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
        {swipDirection === "1" ? <button
          onClick={onNextPage}
          className="bg-gray-700 px-4 py-2 rounded text-white"
          disabled={numPages !== null && currentPageIndex >= numPages-1}
          // style={{ marginInline: "20px" }} // ✅ Keeps spacing consistent
        >
          ⬅ {t("mainSection.next")}
        </button> : <button
          onClick={onPrevPage}
          className="bg-gray-700 px-4 py-2 rounded text-white"
          disabled={numPages !== null && currentPageIndex <= 0}
          // style={{ marginInline: "15px" }} // ✅ Ensures equal spacing
        >
          ⬅  {t("mainSection.prev")}
        </button>}

        {/* ✅ Page Number Display */}
        <span>{t("mainSection.page")} {currentPageIndex + 1} / {numPages || "Loading..."}</span>

        {swipDirection === "1" ? <button
          onClick={onPrevPage}
          className="bg-gray-700 px-4 py-2 rounded text-white"
          disabled={numPages !== null && currentPageIndex <= 0}
          style={{ marginInline: "15px" }} // ✅ Ensures equal spacing
        >
          {t("mainSection.prev")}  ➡
        </button> : <button
          onClick={onNextPage}
          className="bg-gray-700 px-4 py-2 rounded text-white"
          disabled={numPages !== null && currentPageIndex >= numPages-1}
          style={{ marginInline: "20px" }} // ✅ Keeps spacing consistent
        >
          {t("mainSection.next")} ➡
        </button>}
      </div>

      {/* ZOOM controles */}
      <div className="flex items-center gap-3">
        <button
          onClick={onZoomOut}
          className="bg-gray-700 px-4 py-2 rounded text-white"
          disabled={zoom <= minZoom}
        >
          ➖
        </button>
        <span className="text-white"> {t("mainSection.zoom")} {(zoom * 100).toFixed(0)}% </span>
        <button
          onClick={onZoomIn}
          className="bg-gray-700 px-4 py-2 rounded text-white"
          disabled={zoom >= maxZoom}
        >
          ➕
        </button>
      </div>
    </div>
  );
};

export default PDFControlPanel;
