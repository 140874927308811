import React, { useEffect, useState, useCallback } from 'react';
import Chart from './Chart';
import PageRanking from './PageRanking';
import { Stack } from '@mui/material';
import { ShareInstanceOptions } from './ShareInstanceOptions';
import { NumberCard } from './NumberCard';
import MedianPageTimeChart from './MedianPageTimeChart';
import InPageAnalytics from '../InPageAnalytics';
import { useTranslation } from 'react-i18next';

export interface DocumentInstanceIdProps {
  children: React.ReactNode; 
}

export interface AnalyticsPageProps {
  documentId?: string;
}

const AnalyticsPage: React.FC<AnalyticsPageProps> = ({ documentId }) => {  
  const [selectedDocumentInstanceId, setSelectedDocumentInstanceId] = useState<string>();
  const [selectedDocumentId, setSelectedDocumentId] = useState<string | undefined>(documentId);

  const { t } = useTranslation("global");
  
  const handleOptionChange = useCallback((optionId: string) => {
    setSelectedDocumentInstanceId(optionId);
  }, []);

  useEffect(() => {
    if (documentId) {
      setSelectedDocumentId(documentId);
    }
  }, [documentId]);

  const renderAnalytics = () => (
    <Stack direction="column" spacing="20px" padding="10px" marginTop="16px" maxWidth='100%'>
      <Stack direction={{ xs: 'column', sm: 'row' }} style={{ justifyContent: 'space-between' }}>
        <ShareInstanceOptions onOptionChange={handleOptionChange} initialDocumentId={selectedDocumentId} />
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing="10px">
          <NumberCard trend={'up'} title={t("mainSection.shared-num-title")} passcodeId={selectedDocumentInstanceId} documentId={selectedDocumentId} />
        </Stack>
      </Stack>
      <Chart passcodeId={selectedDocumentInstanceId} />
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={10}>
        <PageRanking passcodeId={selectedDocumentInstanceId} />
        <MedianPageTimeChart selectedDocumentInstanceId={selectedDocumentInstanceId} />
      </Stack>
      {/* <InPageAnalytics passcodeId={selectedDocumentInstanceId} docId={selectedDocumentId} /> */}
    </Stack>
  );

  return (
    <div>
      {renderAnalytics()}
    </div>
  );
}

export default AnalyticsPage;
