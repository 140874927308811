import { GTRACKING } from "lib/api/client";
import React, { createContext, useContext, useEffect } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

// Define the tracking context type
interface TrackingContextType {
  trackEvent: (category: string, action: string, label?: string) => void;
}

// Create a context for tracking
const TrackingContext = createContext<TrackingContextType | undefined>(
  undefined
);

// Google Analytics Tracking Provider
export const TrackingProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize(GTRACKING); 
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  const trackEvent = (category: string, action: string, label?: string) => {
    ReactGA.event({ category, action, label });
  };

  return (
    <TrackingContext.Provider value={{ trackEvent }}>
      {children}
    </TrackingContext.Provider>
  );
};

export const useTracking = (): TrackingContextType => {
  const context = useContext(TrackingContext);
  if (!context) {
    throw new Error("useTracking must be used within a TrackingProvider");
  }
  return context;
};
