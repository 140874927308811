import { BASE_URL } from "lib/api/client";

interface SessionResponse {
  session_id?: string;
  sessionId?: string;
  error?: string;
}

interface EventData {
  eventType: string;
  data: any;
}

interface AnalyticsManager {
  sessionId: string | null;
  events: EventData[];
  docuId: string | null;
  trackEvent: (eventType: string, data: any) => void;
  startSession: (passcodeCode: string) => Promise<void>;
  endSession: () => Promise<void>;
  sendData: () => Promise<void>;
}

class AnalyticsManagerImpl implements AnalyticsManager {
  sessionId: string | null = null;
  events: EventData[] = [];
  docuId: string | null = null;
  isSending: boolean = false;
  setSessionState: React.Dispatch<React.SetStateAction<string | null>>;

  constructor(setSessionState: React.Dispatch<React.SetStateAction<string | null>>, docuId: string | null) {
    this.setSessionState = setSessionState;
    this.docuId = docuId;
    this.loadSessionIdFromLocalStorage();
    window.addEventListener('beforeunload', this.handleUnload);
    setInterval(this.sendData, 500);
  }

  trackEvent = (eventType: string, data: any): void => {
    this.saveData({ eventType, data });
  };

  saveData = (event: EventData): void => {
    this.events.push(event);
  };

  startSession = async (passcodeCode: string): Promise<void> => {
    try {
      const userAgent = navigator.userAgent;

      const response = await fetch(`${BASE_URL}/api/v1/page_sessions/start`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          passcode_code: passcodeCode,
          document_id: this.docuId,
          system_info: userAgent
        })
      });

      const data: SessionResponse = await response.json();
      const session_id = data.session_id ?? data.sessionId;
      if (session_id) {
        this.sessionId = session_id;
        localStorage.setItem('session_id', this.sessionId);
        this.loadSessionIdFromLocalStorage();
      } else if (data.error) {
        console.error('Error starting session:', data.error);
      }
    } catch (error) {
      console.error('Failed to start session:', error);
    }
  };

  endSession = async (): Promise<void> => {
    if (this.sessionId) {
      try {
        const response = await fetch(`${BASE_URL}/api/v1/page_sessions/end/${this.sessionId}`, {
          method: 'POST'
        });
        if (response.ok) {
          this.sessionId = null;
          localStorage.removeItem('session_id');
        } else {
          console.error('Failed to end session');
        }
      } catch (error) {
        console.log('Error ending session:', error);
      }
    }
  };

  sendData = async (): Promise<void> => {
    if (this.isSending || this.events.length === 0) {
      return;
    }

    this.isSending = true;

    const sessionId = localStorage.getItem('session_id');
    const eventsWithSessionId = this.events.map(event => ({
      ...event,
      data: {
        ...event.data,
        session_id: sessionId
      }
    }));

    try {
      const response = await fetch(`${BASE_URL}/api/v1/analytics/batch`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ events: eventsWithSessionId })
      });

      if (response.ok) {
        this.events = [];
      } else {
        console.error('Failed to send data');
      }
    } catch (error) {
      console.error('Error sending data:', error);
    } finally {
      this.isSending = false;
    }
  };

  handleUnload = (): void => {
    if (this.sessionId) {
      const sessionPayload = JSON.stringify({ sessionId: this.sessionId });
      navigator.sendBeacon(`${BASE_URL}/api/v1/page_sessions/end/${this.sessionId}`, sessionPayload);
    }

    if (this.events.length > 0) {
      const payload = JSON.stringify({ events: this.events });
      navigator.sendBeacon(`${BASE_URL}/api/v1/analytics/batch`, payload);
    }
  };

  loadSessionIdFromLocalStorage = (): void => {
    const storedSessionId = localStorage.getItem('session_id');
    if (storedSessionId) {
      this.sessionId = storedSessionId;
    }
  };
}

export const createAnalyticsManager = (setSessionState: React.Dispatch<React.SetStateAction<string | null>>, docuId: string | null): AnalyticsManager => {
  return new AnalyticsManagerImpl(setSessionState, docuId);
};
