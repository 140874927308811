import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Typography, Button, TextField } from '@material-ui/core';
import SinglePagePdfViewer from "./SinglePagePdfViewer";
import { requestWrapper } from 'lib/api/auth';
import { createAnalyticsManager } from 'components/utils/analyticsManager';
import { BASE_URL, cloudFrontDomain, s3Domain } from 'lib/api/client';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { Loading } from 'components/SingleComponents/Helpers/Loading';
import { DocumentProps } from '../Search';
import DynamicScriptLoader from '../pardot/DynamicScriptLoader';
import PDFControlPanel from './PDFControlPanel';
import SwipeHintOverlay from 'components/SingleComponents/Helpers/SwipeHintOverlay';

const BASE_ZOOM = 1.0;
const MIN_ZOOM = 0.5;
const MAX_ZOOM = 2.0;

const SharedPDF: React.FC = () => {
  const [searchParams] = useSearchParams();
  const emailParam = searchParams.get('email');
  const docuId = searchParams.get('docuId');
  const [currentDocument, setCurrentDocument] = useState<DocumentProps | null>(null);
  const [isMobile,] = useState(window.innerWidth < 768);
  const [, setInitializeChat] = useState<boolean>(false);
  const [passcode, setPasscode] = useState<string>(emailParam || Cookies.get(`passcode_or_email_${docuId}`) || '');
  const [checkPasscode, setCheckPasscode] = useState<Boolean>(emailParam !== null || Cookies.get(`passcode_or_email_${docuId}`) !== undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState<string>('');
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(0);
  const [pageSessionId, setPageSessionId] = useState<string | null>(null);
  const [zoomProp, setZoomProp] = useState(BASE_ZOOM);
  const [visible, setVisible] = useState(true);

  const analyticsManager = createAnalyticsManager(setPageSessionId, docuId);
  
  const navigate = useNavigate();
  const { t } = useTranslation("global");

  const increaseZoom = () => setZoomProp((prev) => Math.min(prev + 0.1, MAX_ZOOM));
  const decreaseZoom = () => setZoomProp((prev) => Math.max(prev - 0.1, MIN_ZOOM));

  useEffect(() => {
    const timer = setTimeout(() => setVisible(false), 3500);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const fetchDocument = async () => {
      if (!checkPasscode) return;

      setIsLoading(true);
      setIsError(false);

      const docuId = searchParams.get('docuId');
      if (!docuId) {
        console.error('Document ID not specified');
        setIsError(true);
        setIsLoading(false);
        return;
      }

      try {
        const response: DocumentProps = await requestWrapper(
          `${BASE_URL}/api/v1/share/${docuId}`, {
          headers: { passcode: passcode },
        }, false);

        setCurrentDocument(response);
        setIsLoading(false);
        Cookies.set(`passcode_or_email_${docuId}`, passcode);
        await analyticsManager.startSession(passcode);

        const savedPageIndex = localStorage.getItem(`lastPage_${docuId}`);
        if (savedPageIndex !== null) {
          setCurrentPageIndex(parseInt(savedPageIndex, 10));
        } else {
          handlePageChange(0);
        }
      } catch (e) {
        setIsError(true);
        setIsLoading(false);
        setError('資料が見つかりません。削除されたか、非公開に設定されています。');
      }
    };
    fetchDocument();
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      return '';
    };
    
    window.addEventListener('beforeunload', handleBeforeUnload, {capture: true});
    
    return () => {
      console.log("ENDING SESSIONS");
      analyticsManager.endSession();
      window.removeEventListener('beforeunload', handleBeforeUnload, {capture: true});
    };
  }, [checkPasscode, passcode, error, navigate]);

  const handlePageChange = (pageIndex: number) => {
    if (!currentDocument) return;
    const pageCount = currentDocument.page_urls.length;
    if (pageIndex < 0 || pageIndex >= pageCount) return;

    setIsLoading(true);

    setTimeout(() => {
      setCurrentPageIndex(pageIndex);
      analyticsManager.trackEvent('page_change', { page: pageIndex, session_id: pageSessionId });
      localStorage.setItem(`lastPage_${docuId}`, pageIndex.toString());
      setInitializeChat(true);
      setIsLoading(false);
    }, 200);
  };

  const renderPasscode = () => (
    <>
      {
      !emailParam && 
        <form onSubmit={handlePasscodeSubmit}>
          <TextField
            label={t("mainSection.email-label")}
            variant="outlined"
            type="string"
            fullWidth
            value={passcode}
            onChange={handlePasscodeChange}
            required
            style={{ marginBottom: 20 }}
          />
          <Button type="submit" variant="contained" color="primary" disabled={passcode === ""}>
            アクセスする
          </Button>
          {error && <Typography color="error" style={{ marginTop: 20 }}>{error}</Typography>}
        </form>
      }
    </>
  );

  const handlePasscodeChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setPasscode(event.target.value);
  };

  const handlePasscodeSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    setCheckPasscode(true);
  };

  const renderNoDocument = () => (
    <div>{t("mainSection.no-document-found")}</div>
  );

  const renderError = () => (
    <div>{t("mainSection.error-loading-document")}</div>
  );

  const renderLoading = () => <Loading />;

  const renderDocument = () => {
    if (!currentDocument) return null;

    const pageCount = currentDocument.page_urls.length;
    if (currentPageIndex < 0 || currentPageIndex >= pageCount) return;

    return (
      <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#f5f5f5' }}>
        {visible && isMobile && <SwipeHintOverlay />}
        {!visible && <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', backgroundColor: '#fff' }}>
          {/* <Typography variant="body1" style={{ marginBottom: '10px' }}>
            {`${t("mainSection.page")} ${currentPageIndex + 1} / ${pageCount}`}
          </Typography> */}

          <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
            <SinglePagePdfViewer 
              fileUrl={currentDocument.page_urls[currentPageIndex].replace(s3Domain, cloudFrontDomain).split('?')[0]} 
              zoomProp={zoomProp} 
              onNext={() => handlePageChange(currentPageIndex + 1)}
              onPrev={() => handlePageChange(currentPageIndex - 1)}
            />
          </div>

          <div style={{ position: 'fixed', bottom: '20px', display: 'flex', justifyContent: 'center', gap: '10px' }}>
            <PDFControlPanel
              zoom={zoomProp}
              onZoomIn={increaseZoom}
              onZoomOut={decreaseZoom}
              minZoom={MIN_ZOOM}
              maxZoom={MAX_ZOOM}
              currentPageIndex={currentPageIndex}
              numPages={pageCount}
              onNextPage={() => handlePageChange(currentPageIndex + 1)}
              onPrevPage={() => handlePageChange(currentPageIndex - 1)}
            />
          </div>
          <DynamicScriptLoader />
        </div>}
      </div>
    );
  }

  return(
    <>
      {!currentDocument && !Cookies.get(`passcode_or_email_${docuId}`)
       ? renderPasscode()
       : isLoading
       ? renderLoading()
       : isError
       ? renderError()
       : currentDocument
       ? renderDocument()
       : renderNoDocument()}
    </>
  );
};

export default SharedPDF;
