import axios from "axios";
import { BASE_URL } from "lib/api/client";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const DynamicScriptLoader: React.FC = () => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const loadScript = async () => {
      try {
        const docuId = searchParams.get("docuId");
        const response = await axios({
          method: "GET",
          url: `${BASE_URL}/api/v1/fetch_script?docuId=${docuId}`,
        });

        if (response.status !== 200) throw new Error("Failed to load script from API");

        const scriptContent = response.data.script;

        // Create a new script element
        const scriptElement = document.createElement("script");
        scriptElement.textContent = scriptContent;
        scriptElement.async = true;

        // Append script to the footer (or body)
        document.body.appendChild(scriptElement);

        return () => {
          // Cleanup: Remove script when component unmounts
          document.body.removeChild(scriptElement);
        };
      } catch (error) {
        console.error("Error loading script:", error);
      }
    };

    loadScript();
  }, []);

  return null;
};

export default DynamicScriptLoader;
