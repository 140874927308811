import client from "lib/api/client";
import Cookies from "js-cookie";
import ReactGA from "react-ga4";
import axios, { AxiosRequestConfig, Method } from "axios";

import { SignUpParams, SignInParams } from "interfaces/index";
import { trackedEndpoints } from "components/utils/Constants";

export const signUp = (params: SignUpParams) => {
  return client.post("auth", params);
};

export const signIn = async (params: SignInParams) => {
  const response = await client.post("auth/sign_in", params);
  const { headers } = response;
  Cookies.set("_access_token", headers["access-token"]);
  Cookies.set("_client", headers["client"]);
  Cookies.set("_uid", headers["uid"]);
  return response;
};

export const signOut = () => {
  return client.delete("auth/sign_out", {
    headers: {
      "access-token": Cookies.get("_access_token"),
      "client": Cookies.get("_client"),
      "uid": Cookies.get("_uid"),
    },
  });
};

export const getCurrentUser = () => {
  const jwt = Cookies.get("jwt");
  if (!jwt) {
    return Promise.resolve({ data: { failed: true, data: null } });
  }

  return { data: { failed: false, data: Cookies.get("user") } }
};

interface RequestWrapperOptions {
  method?: Method;
  body?: any;
  headers?: { [key: string]: string };
}

/*
* We want to record api calls only for certain Apis. 
* Currently we are only tracking the analytics endpoint call.
* This is so we can track user usage.
*/
const shouldApiCAllFunction: (endpoint: string) => boolean = (
  endpoint: string,
) => {
  endpoint.includes("/api/v1/page_visited/page_rank_by_visits_for_passcode");
  return trackedEndpoints.some(path => endpoint.includes(path));
}


/**
 * Logs API calls to Google Analytics (GA4)
 */
const logApiCallToGA = (
  endpoint: string,
  method: string,
  status: number,
  requestBody?: any
) => {
  try {
    const safeBody = requestBody
      ? JSON.stringify(requestBody, (key, value) =>
          key.toLowerCase().includes("password") || key.toLowerCase().includes("token")
            ? "****"
            : value
        )
      : null;

    if (!ReactGA) {
      console.warn("Google Analytics (GA4) is not initialized");
      return;
    }

    ReactGA.event({
      category: "API Request",
      action: `${method} ${endpoint}`,
      label: `Status: ${status}, Body: ${safeBody}`,
    });
  } catch (error) {
    console.error("Failed to log API call to GA4:", error);
  }
};

const updateAuthTokens = (headers: any) => {
  if (headers["access-token"]) {
    Cookies.set("_access_token", headers["access-token"]);
  }
  if (headers["client"]) {
    Cookies.set("_client", headers["client"]);
  }
  if (headers["uid"]) {
    Cookies.set("_uid", headers["uid"]);
  }
};

/**
 * A helper function to make authenticated API requests.
 * @param endpoint - The API endpoint to call.
 * @param options - Additional options for the request such as method and body.
 * @returns A promise that resolves to the API response.
 */
export const requestWrapper = async (
  endpoint: string,
  options: RequestWrapperOptions = {},
  isProtected: Boolean = true
): Promise<any> => {
  const jwt = Cookies.get("jwt");

  if (!jwt && isProtected) {
    console.error("Authentication tokens are missing.");
    return Promise.reject("Authentication tokens are missing");
  }

  const headers = {
    "Authorization": `Bearer ${jwt}`,
    ...options.headers,
  };

  const config: AxiosRequestConfig = {
    method: options.method || "GET",
    url: endpoint,
    headers: headers,
    data: options.body || null,
  };

  try {
    const response = await axios(config);
    updateAuthTokens(response.headers);
    if (shouldApiCAllFunction(endpoint)) {
      console.log("LOGGING CLIENT USAGE")
      logApiCallToGA(endpoint, config.method as string, response.status, config.data);
    }
    return response.data;
  } catch (error) {
    console.error("Request failed:", error);
    if (axios.isAxiosError(error)) {
      const statusCode = error.response?.status;
      if (statusCode === 402) {
        window.location.href = 'https://landing.fileslabs.com/contact-us';
      }
    }
    throw error;
  }
};

