import React, { useState } from "react";
import { Container, Grid, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Footer from "./Footer";
import VerticalHeader from "./VerticalHeader";
import { useLocation } from "react-router-dom";
import HorizontalHeader from "./HorizonatalHeader";
import MenuIcon from "@material-ui/icons/Menu";

const drawerWidth = 100;

const verticalUseStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh', // Ensures full viewport height
    flexDirection: 'column',
    overflow: 'hidden',
  },
  sidebar: {
    width: drawerWidth,
    flexShrink: 0,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  sidebarHidden: {
    width: 0,
    flexShrink: 0,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentWrapper: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between', // Push footer to bottom without extra space
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    paddingLeft: "32px",
    paddingTop: "32px",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  contentWrapperExpanded: {
    marginLeft: 0,
    width: "100%",
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between', // Ensures footer doesn't cause extra space
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center', // Centers content without extra space
    alignItems: 'center',
    marginTop: "32px",
    padding: theme.spacing(3),
  },
  gridItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 0, // ⬅️ Prevents unnecessary stretching
    width: "100%",
    height: "100%",
    margin: 0,
    padding: 0,
  },
  footer: {
    marginTop: 'auto', // Push footer to the bottom without extra space
  },
  menuButton: {
    position: "absolute",
    top: theme.spacing(2),
    left: theme.spacing(2),
    zIndex: 1300,
  },
}));

const horizontalUseStyles = makeStyles(() => ({
  container: {
    marginTop: "3rem",
  },
  gridItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 0, // ⬅️ Prevents unnecessary stretching
    width: "100%",
    height: "100%",
    margin: 0,
    padding: 0,
  },
}));

const HorizontalLayout: React.FC<CommonLayoutProps> = ({ children }) => {
  const styleClasses = horizontalUseStyles();
  return (
    <>
      <header>
        <HorizontalHeader />
      </header>
      <main>
        <Container maxWidth="lg" className={styleClasses.container}>
          <Grid container>
            <Grid item xs={12}>
              {children}
            </Grid>
          </Grid>
        </Container>
      </main>
      <Footer />
    </>
  );
};

const VerticalLayout: React.FC<CommonLayoutProps> = ({ children }) => {
  const classes = verticalUseStyles();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div className={classes.root}>
      <IconButton
        className={classes.menuButton}
        onClick={toggleSidebar}
        aria-label="toggle-sidebar"
      >
        <MenuIcon />
      </IconButton>
      <nav className={sidebarOpen ? classes.sidebar : classes.sidebarHidden}>
        {sidebarOpen && <VerticalHeader />}
      </nav>
      <div
        className={
          sidebarOpen
            ? classes.contentWrapper
            : classes.contentWrapperExpanded
        }
      >
        <main className={classes.content}>
          <Grid container className={classes.gridItem}>
            <Grid item xs={12}>
              {children}
            </Grid>
          </Grid>
        </main>
        <footer className={classes.footer}>
          <Footer />
        </footer>
      </div>
    </div>
  );
};

interface CommonLayoutProps {
  children: React.ReactNode;
}

const CommonLayout: React.FC<CommonLayoutProps> = ({ children }) => {
  return <VerticalLayout>{children}</VerticalLayout>
};

export default CommonLayout;
