import React from "react";
import { motion } from "framer-motion";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import { LOTTIE_SWIPE_ANIMATION } from "components/utils/Constants";

const SwipeHintOverlay: React.FC = () => {
  return (
    (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 pointer-events-auto z-50">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="flex items-center justify-center"
        >
          <DotLottieReact
            src={LOTTIE_SWIPE_ANIMATION}
            style={{ width: 200, height: 200 }}
            loop
            autoplay
          />
        </motion.div>
      </div>
    )
  );
};

export default SwipeHintOverlay;
