import { pdfjs } from 'react-pdf';

export const PDFJS_WORKER = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`;

export const trackedEndpoints = [
  "/api/v1/page_visited/page_rank_by_visits_for_passcode",
];

export const BASE_COLOR = "#3f51b5";

export const LOTTIE_SWIPE_ANIMATION = "https://lottie.host/823954a0-2c29-429d-9e97-d4951254ad7b/k5tJU0tKzy.lottie";
