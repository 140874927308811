import applyCaseMiddleware from "axios-case-converter"
import axios from "axios"
import exp from "constants";

const options = {
  ignoreHeaders: true 
}

export const GTRACKING = "G-M1E1EKNL90"
export const LANDING_PAGE_URL = "https://landing.fileslabs.com/";

// export const BASE_URL = "https://document.byphr.com"

// export const BASE_URL = "http://0.0.0.0:3002"
export const BASE_URL = "https://files-labs.com"



// export const FRONT_URL = "http://localhost:3000"
export const FRONT_URL = "https://fileslabs.com"

export const s3Domain = "https://livedoc-osaka.s3.ap-northeast-3.amazonaws.com";
export const cloudFrontDomain = "https://d22c8rpnb7f7vk.cloudfront.net";
export const GOOGLE_AUTH_CLIENT_KEY = "385857307770-tugb07ia4jo32dmv0r6ph1dddofvnt5i.apps.googleusercontent.com";

const client = applyCaseMiddleware(axios.create({
  baseURL: `${BASE_URL}/api/v1`
}), options)

export default client
