import React, { useState, useEffect, useRef, useCallback } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useSwipeable } from "react-swipeable";
import { PDFJS_WORKER } from "components/utils/Constants";
import PDFControlPanel from "components/pages/PDFView/PDFControlPanel";
import { useLocation } from 'react-router-dom';

pdfjs.GlobalWorkerOptions.workerSrc = PDFJS_WORKER;

interface PDFViewerProps {
  fileUrl: string;
}

const BASE_ZOOM = window.innerWidth <= 767 ? 0.8 : 1;
const MIN_ZOOM = 0.5;
const MAX_ZOOM = 2;

const PDFViewer: React.FC<PDFViewerProps> = ({ fileUrl }) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [isMobile,] = useState(window.innerWidth < 768);
  const [zoom, setZoom] = useState(BASE_ZOOM); // ✅ Default zoom auto-adjusts
  const pdfContainerRef = useRef<HTMLDivElement | null>(null);
  const [containerSize, setContainerSize] = useState({ width: 800, height: 1000 });

  const location = useLocation();
  const path = location.pathname;

  // ✅ Automatically Adjust for Mobile/Desktop
  useEffect(() => {
    const updateSize = () => {
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;

      // const isMobile = screenWidth < 768;
      const width = isMobile ? screenWidth * 0.9 : Math.min(screenWidth * 0.6, 800); // Mobile: 90% width, Desktop: Max 800px
      let height = isMobile ? screenHeight * 0.6 : Math.min(screenHeight * 0.7, 600); // Mobile: 60% height, Desktop: Max 600px
      setContainerSize({ width, height });

      let newZoom = isMobile ? (width / 800) * 2 : (width / 800) / 2; // Adjust zoom dynamically
      
      /* This is to adjust the pdf in the analytic page */
      if (path.startsWith("/view") && !isMobile) {
        newZoom = newZoom * 2
      }
    
      setZoom(newZoom);
    };

    updateSize();
    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const increaseZoom = () => setZoom((prev) => Math.min(prev + 0.1, MAX_ZOOM));
  const decreaseZoom = () => setZoom((prev) => Math.max(prev - 0.1, MIN_ZOOM));

  // ✅ Next/Previous Page Functions
  const goToNextPage = () => setCurrentPageIndex((prev) => Math.min(prev + 1, numPages || prev));
  const goToPrevPage = () => setCurrentPageIndex((prev) => Math.max(prev - 1, 0));

  // ✅ Swipe Gestures for Mobile Navigation
  const handlers = useSwipeable({
    onSwipedLeft: goToNextPage,
    onSwipedRight: goToPrevPage,
    // onSwipedLeft: goToPrevPage,
    // onSwipedRight: goToNextPage,
    preventScrollOnSwipe: true,
    trackTouch: true,
  });

  // ✅ Combine refs properly
  const mergedRef = useCallback(
    (el: HTMLDivElement | null) => {
      pdfContainerRef.current = el;
      handlers.ref(el); // Attach swipe handlers
    },
    [handlers]
  );

  console.log("containerSize:", containerSize);

  return (
    <div className="flex flex-col items-center w-full px-4"> {/* ✅ Responsive Padding */}
      {/* ✅ Framed Container for PDF */}
      <div
        ref={mergedRef}
        className="border-8 border-gray-600 shadow-lg bg-gray-800 text-white flex items-center justify-center rounded-lg mx-auto"
        style={{
          // width: `${containerSize.width}px`,
          // height: `${containerSize.height}px`,
          overflow: "hidden",
          padding: "10px",
        }}
      >
        {/* ✅ PDF Background for Frame Effect */}
        <div className="bg-gray-700 p-4 rounded-md flex items-center justify-center w-full h-full">
          <Document file={fileUrl} onLoadSuccess={onDocumentLoadSuccess}>
            <Page
              pageNumber={currentPageIndex + 1}
              width={containerSize.width * zoom} // ✅ Keeps PDF contained and zoomable
              renderTextLayer={false}
              renderAnnotationLayer={false}
            />
          </Document>
        </div>
      </div>
      {/* ✅ Fixed Bottom Control Panel (Mobile-Friendly) */}
      <PDFControlPanel
        zoom={zoom}
        onZoomIn={increaseZoom}
        onZoomOut={decreaseZoom}
        minZoom={MIN_ZOOM}
        maxZoom={MAX_ZOOM}
        currentPageIndex={currentPageIndex}
        numPages={numPages}
        onNextPage={goToNextPage}
        onPrevPage={goToPrevPage}
      />
    </div>
  );
};

export default PDFViewer;
